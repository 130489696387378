<template>
    <div class="goods">
        <Breadcrumb :currentPage="currentPage"></Breadcrumb>
        <TitleTabs :tabsData="tabsData" @changeTab="changeTab"></TitleTabs>
        <TabItems @changeItem="changeItem" :tabCurrentIndex="secondTabCurrentIndex"></TabItems>
        <Introduce @changeDownload="changeDownload" v-if="secondTabCurrentIndex===0" :introduce="introduceData"></Introduce>
        <Teach v-else-if="secondTabCurrentIndex===1" :teachData="teachData"></Teach>
        <Problem v-else-if="secondTabCurrentIndex===2" :problemData="problemData"></Problem>
        <Download v-else-if="secondTabCurrentIndex===3" :downloadData="downloadData"></Download>
    </div>
</template>

<script>
import Breadcrumb  from '../../components/Breadcrumb'
import TitleTabs from '../../components/TitleTabs'
import TabItems from '../../components/TabItems'
import Introduce from '../../components/Introduce'
import Teach from '../../components/Teach'
import Problem from '../../components/Problem'
import Download from '../../components/Download'

export default {
    name: 'Product',

    data() {
        return {  
            currentPage: '商品管理',
            tabsData: [],
            tabsItems: [],

            firstTabCurrentIndex: 0,

            introduceData: {},
            // 二级tabs索引
            secondTabCurrentIndex: 0,

            // 使用教程数据
            teachData: {},

            // problem组件数据
            problemData: {},

            downloadData: {}
        };
    },

    mounted() {
    },

    methods: {
        toProduct() {
            this.$router.replace('/Product')
        },

        changeDownload() {
            this.secondTabCurrentIndex = 3
            if(this.firstTabCurrentIndex === 0) {
                this.getDistributionDownload()
            }else if (this.firstTabCurrentIndex === 1) {
                this.getGoodsNumberDownload()
            }
        },

        async changeTab(index) {
            if(index === 0) {
                this.firstTabCurrentIndex = 0
                if(this.secondTabCurrentIndex === 0) {
                    this.secondTabCurrentIndex = 0
                    this.getDistributionIntroduce()
                }else if(this.secondTabCurrentIndex === 1) {
                    this.secondTabCurrentIndex = 1
                    this.getDistributionTeach()
                }else if(this.secondTabCurrentIndex === 2) {
                    this.secondTabCurrentIndex = 2
                    this.getDistributionProblem()
                }else if(this.secondTabCurrentIndex === 3) {
                    this.secondTabCurrentIndex = 3
                    this.getDistributionDownload()
                }
            }else if (index === 1) {
                this.firstTabCurrentIndex = 1
                if(this.secondTabCurrentIndex === 0) {
                    this.secondTabCurrentIndex = 0
                    this.getGoodsNumberIntroduce()
                }else if(this.secondTabCurrentIndex === 1) {
                    this.secondTabCurrentIndex = 1
                    this.getGoodsNumberTeach()
                }else if(this.secondTabCurrentIndex === 2) {
                    this.secondTabCurrentIndex = 2
                    this.getGoodsNumberProblem()
                }else if(this.secondTabCurrentIndex === 3) {
                    this.secondTabCurrentIndex = 3
                    this.getGoodsNumberDownload()
                }
            }
            
        },

        changeItem(index) {
            this.secondTabCurrentIndex = index
            if(this.firstTabCurrentIndex === 0) {
                if(this.secondTabCurrentIndex === 0) {
                    this.secondTabCurrentIndex = 0
                    this.getDistributionIntroduce()
                }else if(this.secondTabCurrentIndex === 1) {
                    this.secondTabCurrentIndex = 1
                    this.getDistributionTeach()
                }else if(this.secondTabCurrentIndex === 2) {
                    this.secondTabCurrentIndex = 2
                    this.getDistributionProblem()
                }else if(this.secondTabCurrentIndex === 3) {
                    this.secondTabCurrentIndex = 3
                    this.getDistributionDownload()
                }
            }else if(this.firstTabCurrentIndex === 1){
                if(this.secondTabCurrentIndex === 0) {
                    this.secondTabCurrentIndex = 0
                    this.getGoodsNumberIntroduce()
                }else if(this.secondTabCurrentIndex === 1) {
                    this.secondTabCurrentIndex = 1
                    this.getGoodsNumberTeach()
                }else if(this.secondTabCurrentIndex === 2) {
                    this.secondTabCurrentIndex = 2
                    this.getGoodsNumberProblem()
                }else if(this.secondTabCurrentIndex === 3) {
                    this.secondTabCurrentIndex = 3
                    this.getGoodsNumberDownload()
                }
            }
        },


        async getDistributionDownload() {
            const {code,data} = await this.$http.get('/distributionDownload')
            if(code===200) {
                this.downloadData = data
            }
        },

        async getGoodsNumberDownload() {
            const {code,data} = await this.$http.get('/goodsNumbersDownload')
            if(code===200) {
                this.downloadData = data
            }
        },

        async getGoodsNumberProblem() {
            const { code, data } = await this.$http.get('/goodsNumberProblem')
            if (code === 200) {
                this.problemData = data
            }
        },

        async getDistributionProblem() {
            const { code, data } = await this.$http.get('/distributionProblem')
            if(code === 200) {
                this.problemData = data
            }
        },

        async getGoodsNumberTeach() {
            const { code, data } = await this.$http.get('/goodsNumberTeach')
            if(code === 200) {
                this.teachData = data
            }
        },

        async getDistributionTeach() {
            const { code, data } = await this.$http.get('/distributionTeach')
            if(code === 200) {
                this.teachData = data
            }
        },

        async getTitleTabs() {
            const { code, data } = await this.$http.get('/tabs')
            if(code === 200) {
                this.tabsData = data
            }
        },

        async getDistributionIntroduce() {
            const { code, data } = await this.$http.get('/distributionIntroduce')
            if(code === 200) {
                this.introduceData = data
            }
        },

        async getGoodsNumberIntroduce() {
            const { code, data } = await this.$http.get('/goodsNumberIntroduce')
            if(code === 200) {
                this.introduceData = data
            }
        },

    },

    components: {
        Breadcrumb,
        TitleTabs,
        TabItems,
        Introduce,
        Teach,
        Problem,
        Download
    },

    mounted() {
        this.getTitleTabs()
        this.getDistributionIntroduce()
    }
};
</script>

<style lang="less" scoped>
    .breadcrumb {
        padding: 10px 20px;
        margin: 20px auto;
        border-bottom: 1px solid rgba(0,0,0,.06);
        ul {
            display: flex;
            li {
                margin-right: 8px;
                .home {
                    color: rgba(0,0,0,.45);
                }
            }
        }
    }
</style>